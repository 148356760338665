<template>
  <div class="outer">
    <div class="cont-item">
      <h4>模板基础信息</h4>
      <div
        class="option"
        style="padding-left: 0;"
      >
        <i class="must-icon">*</i>
        <span>模板名称：</span>
        <el-input
          v-model="moduleName"
          placeholder="请输入名称"
          class="w240"
          size="mini"
          :maxlength="30"
          :disabled="!isEdit"
        ></el-input>
      </div>
    </div>
    <div class="cont-item">
      <h4>发货区域设置【发货区域和付费区域至少设置一项】</h4>
      <div class="option">
        <span>包邮区域：</span>
        <el-radio-group v-model="areaType">
          <el-radio :label="1">
            全国
          </el-radio>
          <el-radio :label="2">
            部分地区
          </el-radio>
        </el-radio-group>
      </div>
      <div
        v-if="areaType !== 1"
        class="option"
        style="margin-top: 10px;"
      >
        <span style="margin-right: 70px;"></span>
        <el-cascader
          v-model="freeValue.area_split.all"
          :options="areaOptions"
          :props="areaProps"
          clearable
          :disabled="!isEdit"
          @visible-change="(bol) => visibleChange(bol, freeValue.area_split.all)"
        ></el-cascader>
      </div>
      <p
        v-if="isShowCont && areaType !== 1"
        class="remark"
      >
        请点击输入框内容区域可编辑地址
      </p>
    </div>
    <div
      v-if="areaType !== 1"
      class="cont-item"
    >
      <h4>买家付邮费区域【发货区域和付费区域至少设置一项】</h4>
      <div class="option">
        <span>计费方式：</span>
        <el-radio
          v-model="radio"
          label="1"
          :disabled="!isEdit"
        >
          按件数计费
        </el-radio>
      </div>
      <p class="main">
        生效范围：合并支付时，店铺内绑定该模板的多个商品（如有）会一起计算运费，<br />
        运费按件数平分到各子单；非合并支付时，针对付款的单个商品计算运费。
      </p>
      <div class="table-box">
        <el-table
          :data="tableData"
          :header-row-style="{'font-weight': 400, 'color': '#222', 'font-size': '14px'}"
        >
          <el-table-column
            prop=""
            label="运送到"
            width="200"
          >
            <template slot-scope="scope">
              <div class="address-box">
                <el-cascader
                  v-model="scope.row.area_split.all"
                  :options="areaOptions"
                  :props="areaProps"
                  clearable
                  :disabled="(scope.row.disabled && scope.row.id !== 0)"
                  @visible-change="(bol) => visibleChange(bol, scope.row.area_split.all)"
                ></el-cascader>
                <el-button
                  v-if="scope.row.id && isEdit"
                  size="mini"
                  type="text"
                  @click="scope.row.disabled = !scope.row.disabled"
                >
                  {{ scope.row.disabled ? '编辑' : '完成' }}
                </el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="first_num"
            label="首件数(件)"
          >
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.first_num"
                :disabled="!isEdit"
                class="w100"
                size="mini"
                max="999"
                min="1"
                type="number"
                @input="fixInteger(scope.row.first_num, scope.$index, 'first_num')"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="first_fee"
            label="首费(元)"
          >
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.first_fee"
                :disabled="!isEdit"
                class="w100"
                size="mini"
                max="999"
                min="1"
                type="number"
                @input="firstCost(scope.row.first_fee, scope.$index, 'first_fee', scope.row.each_add_fee)"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="each_add"
            label="续件数(件)"
          >
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.each_add"
                :disabled="!isEdit"
                class="w100"
                size="mini"
                max="999"
                min="1"
                type="number"
                @input="fixInteger(scope.row.each_add, scope.$index, 'each_add')"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="each_add_fee"
            label="续费(元)"
          >
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.each_add_fee"
                :disabled="!isEdit"
                class="w100"
                size="mini"
                max="999"
                min="1"
                type="number"
                @input="fixNextCost(scope.row.each_add_fee, scope.row.first_fee, scope.$index)"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop=""
            label="包邮条件"
            width="220"
          >
            <template slot-scope="scope">
              <div class="condition">
                满
                <el-input
                  v-model="scope.row.free_value"
                  :disabled="!isEdit"
                  type="number"
                  class="w160"
                  size="mini"
                  max="999"
                  @input="fixValue(scope.row.free_value, scope.row.free_type, scope.$index, 'free_value', 1)"
                >
                  <el-select
                    slot="append"
                    v-model="scope.row.free_type"
                    :disabled="!isEdit"
                    placeholder=""
                    class="w65"
                    @change="fixValue(scope.row.free_value, scope.row.free_type, scope.$index, 'free_value', 2)"
                  >
                    <el-option
                      label=" "
                      value="0"
                    ></el-option>
                    <el-option
                      label="件"
                      value="1"
                    ></el-option>
                    <el-option
                      label="元"
                      value="2"
                    ></el-option>
                  </el-select>
                </el-input>
                包邮
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="isEdit"
            prop=""
            label="操作"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="deleteModel(scope.row.id, scope.$index)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-button
        v-if="isEdit"
        size="mini"
        type="primary"
        plain
        @click="addCostModel"
      >
        + 添加一条
      </el-button>
    </div>
    <div
      v-if="isEdit"
      class="submit-area"
    >
      <el-button
        size="mini"
        type="primary"
        @click="submit"
      >
        提交
      </el-button>
      <el-button
        size="mini"
        type="primary"
        plain
        @click="goPer"
      >
        取消
      </el-button>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'FreightDetail',
  data() {
    return {
      moduleName: '',
      radio: '1',
      templateId: '',
      templateType: '',
      tableData: [],
      areaProps: { // 地址得prop属性
        multiple: true,
        value: 'id',
        label: 'name',
        children: 'rows'
      },
      areaOptions: [], // 包邮地址数据列
      freeValue: {
        area_split: {
          all: []
        }
      }, // 包邮地区值
      isShowCont: false,
      isEdit: true,
      areaType: 1 // 包邮区域类型
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.isShowCont = true
    }
    this.templateId = this.$route.query.id // 获取模板id
    this.templateType = this.$route.query.type // 获取模板类型
    this.isEdit = !this.$route.query.read
    this.getAreaList()
  },
  methods: {
    // 验证包邮条件的值
    fixValue(val, type, i, name, num) {
      let newTable = [...this.tableData]
      if (parseInt(type, 10) === 0) {
        if (num === 1) {
          this.$message.error('请先选择包邮条件类型')
        }
        newTable[i].free_value = ''
        this.tableData = newTable
      } else if (parseInt(type, 10) === 1) {
        this.fixInteger(val, i, name)
      } else if (parseInt(type, 10) === 2) {
        this.fixDecimal(val, i, name)
      }
    },
    // 续费验证
    fixNextCost(val, first, i) {
      let newTable = [...this.tableData]
      if (parseFloat(val) < 0 && val !== '') {
        newTable[i].each_add_fee = 0
      } else if (parseFloat(val) > 999) {
        newTable[i].each_add_fee = 999
      } else if (val === '') {
        newTable[i].each_add_fee = ''
      } else if (parseFloat(val) > parseFloat(first)) {
        this.$message.error('续件费用不能大于首件费用')
        newTable[i].each_add_fee = ''
      } else {
        newTable[i].each_add_fee = val.match(/\d+(\.\d{0,2})?/)[0]
      }
      this.tableData = newTable
    },
    // 付费区域正整数验证
    fixInteger(val, i, name) {
      let newTable = [...this.tableData]
      if (parseFloat(val) < 1 && val !== '') {
        newTable[i][name] = 1
      } else if (parseFloat(val) > 999) {
        newTable[i][name] = 999
      } else if (val === '') {
        newTable[i][name] = ''
      } else {
        newTable[i][name] = val.replace(/[^\d]/g, '')
      }
      this.tableData = newTable
    },
    // 付费区域限制两位小数
    fixDecimal(val, i, name) {
      let newTable = [...this.tableData]
      if (parseFloat(val) < 1 && val !== '') {
        newTable[i][name] = 1
      } else if (parseFloat(val) > 999) {
        newTable[i][name] = 999
      } else if (val === '') {
        newTable[i][name] = ''
      } else {
        newTable[i][name] = val.match(/\d+(\.\d{0,2})?/)[0]
      }
      this.tableData = newTable
    },
    // 首件续费校验
    firstCost(val, i, name, first) {
      let newTable = [...this.tableData]
      if (parseFloat(val) < parseFloat(first)) {
        this.$message.error('首件费用不能小于续件费用')
        newTable[i].first_fee = ''
        this.tableData = newTable
        return false
      }
      this.fixDecimal(val, i, name)
    },
    // 返回上一页
    goPer() {
      this.$router.push({
        path: '/freight/list'
      })
    },
    // 地址去重
    visibleChange(bol, val) {
      const { tableData, freeValue, areaOptions } = this
      const arr = []
      const arr2 = []
      const areas = [...areaOptions]
      if (val) {
        val.forEach(item => {
          if (Array.isArray(item)) {
            arr2.push(item[0])
          } else {
            arr2.push(item)
          }
        })
      }
      if (bol) {
        freeValue.area_split.all.forEach(item => {
          if (Array.isArray(item)) {
            if (!arr.includes(item[0])) {
              arr.push(item[0])
            }
          } else {
            if (!arr.includes(item)) {
              arr.push(item)
            }
          }
        })
        tableData.forEach(item => {
          item.area_split.all.forEach(t => {
            if (Array.isArray(t)) {
              if (!arr.includes(t[0])) {
                arr.push(t[0])
              }
            } else {
              if (!arr.includes(t)) {
                arr.push(t)
              }
            }
          })
        })
        let resArr = []
        arr.forEach((t, i) => {
          if (!arr2.includes(t)) {
            resArr.push(t)
          }
        })
        areas.forEach(item => {
          if (resArr.includes(item.id)) {
            item.disabled = true
          } else {
            item.disabled = false
          }
        })
        this.areaOptions = areas
      }
    },
    // 增加一条付费模板
    addCostModel() {
      const arr = [...this.tableData]
      const initial = {
        id: 0,
        area_split: {
          all: []
        },
        fee_type: 0,
        first_num: '',
        first_fee: '',
        each_add: '',
        each_add_fee: '',
        free_type: '0',
        free_value: '',
        disabled: true
      }
      arr.push(initial)
      this.tableData = arr
    },
    // 删除模板
    async deleteModel(id, i) {
      const _this = this
      let newTable = []
      if (id !== 0) {
        // 编辑
        const successStatus = await _this.$axios.post(_this.$api.Freight.delete_rule, {
          template_id: this.templateId,
          rule_id: id
        }).then((res) => {
          if (res.error === 0) {
            _this.$message.success('删除成功')
            return res.error
          } else {
            _this.$message.error(res.msg)
          }
        })
        if (successStatus === 0) {
          newTable = await _this.getDetail(1)
        }
      } else {
        // 新增
        const arr = _this.tableData
        arr.splice(i, 1)
        _this.tableData = arr
      }
    },

    // 获取地址列表
    getAreaList() {
      this.$axios({
        method: 'post',
        url: this.$api.Address.get_address_list,
        data: {}
      }).then((res) => {
        let r = res
        if (r.error === 0) {
          const data = r.data || []
          this.areaOptions = _.cloneDeep(data)
          if (this.templateId) {
            this.getDetail()
          }
        }
      })
    },
    // 转换地区数据结构
    getAreaValue(data) {
      let r = {}
      if (data) {
        data.forEach(item => {
          r[item] = []
        })
      }
      return r
    },
    // 提交
    submit() {
      const _this = this
      let isNext = true
      const {
        templateId,
        templateType,
        moduleName,
        tableData,
        freeValue,
        areaType,
        areaOptions
      } = _this
      if (moduleName === '') {
        isNext = false
      } else if (areaType !== 1 && freeValue.area_split.all.length <= 0 && tableData.length <= 0) {
        isNext = false
      } else {
        isNext = true
      }
      let allProvince = []
      if (areaType === 1) {
        areaOptions.forEach(item => {
          allProvince.push(item.id)
        })
      } else {
        allProvince = freeValue.area_split.all
      }
      // 转换规则数据结构
      const ruleList = [{
        id: freeValue.id || 0,
        fee_type: 0,
        first_num: 0,
        first_fee: 0,
        each_add: 0,
        each_add_fee: 0,
        free_type: 0,
        free_value: 0,
        area_ids: _this.getAreaValue(allProvince)
      }]
      if (areaType !== 1) {
        tableData.forEach(item => {
          if (item.area_split.all.length <= 0 || item.first_num === '' || item.first_fee === '' || item.each_add === '' || item.each_add_fee === '') {
            isNext = false
          }
          if (item.free_type !== '0') {
            if (item.free_value === '') {
              isNext = false
            }
          }
          ruleList.push({ // 添加不包邮数据
            id: item.id || 0,
            fee_type: 1,
            first_num: item.first_num,
            first_fee: item.first_fee,
            each_add: item.each_add,
            each_add_fee: item.each_add_fee,
            free_type: item.free_type,
            free_value: item.free_value,
            area_ids: _this.getAreaValue(item.area_split.all)
          })
        })
      }
      if (!isNext) {
        _this.$message.error('缺少必填项')
        return false
      }
      _this.$axios({
        method: 'post',
        url: _this.$api.Freight.add_save,
        data: {
          template_id: templateId || 0,
          template_type: templateType,
          base_template: JSON.stringify({
            title: moduleName
          }),
          rule_list: JSON.stringify(ruleList),
          area_type: areaType
        }
      }).then(res => {
        const r = res || {}
        if (r.error === 0) {
          _this.$message.success('保存成功')
          _this.$router.push({
            path: '/freight/list'
          })
        } else {
          _this.$message.error(r.msg)
        }
      })
    },
    // 详情
    async getDetail(type) {
      const _this = this
      let freeValue = {}
      let costValue = []
      const data = await _this.$axios({
        method: 'post',
        url: _this.$api.Freight.git_rule_list,
        data: { template_id: _this.templateId }
      }).then((res) => {
        let r = res || {}
        if (r.error === 0) {
          const free = r.data.rule_list || []
          free.forEach(item => {
            if (item.fee_type === 0) {
              // =34证明全国
              if (item.area_split.all.length === 34) {
                _this.areaType = 1
              } else {
                _this.areaType = 2
              }
              freeValue = item
            } else if (item.fee_type === 1) {
              costValue.push({
                id: item.id,
                area_split: {
                  all: (item.area_split && item.area_split.all) || []
                },
                fee_type: item.fee_type,
                first_num: item.first_num,
                first_fee: item.first_fee,
                each_add: item.each_add,
                each_add_fee: item.each_add_fee,
                free_type: String(item.free_type),
                free_value: item.free_type === 0 ? '' : item.free_value,
                disabled: true
              })
            }
          })
          _this.moduleName = r.data.title || ''
          _this.freeValue = freeValue
          _this.tableData = costValue
        }
        return costValue
      })
      return data
    }
  }
}
</script>
<style scoped lang="scss">
.outer {
  width: 100%;
  min-height: 100%;
}
.cont-item {
  margin-bottom: 40px;
  &>h4 {
    font-size: 18px;
    color: #222;
    font-weight: bold;
    margin-bottom: 20px;
  }
  &>.option {
    display: flex;
    align-items: center;
    padding-left: 16px;
    span {
      white-space: nowrap;
      font-size: 14px;
    }
  }
  .main {
    color: #E02020;
    margin-top: 14px;
    margin-bottom: 28px;
    padding-left: 16px;
    line-height:21px;
  }
  .table-box {
    border: 1px solid #dfe6ec;
    margin-bottom: 20px;
    .address-box {
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
    }
    .address-list {
      width: 120px;
      min-height: 100px;
      margin-right: 10px;
    }
  }
}
.must-icon {
  color: #E02020;
  margin-right: 10px;
}
.submit-area {
  margin-top: 94px;
}
.w160 {
  width: 160px !important;
}
.w65 {
  width: 65px !important;
}
.condition {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.remark {
  padding-left: 100px;
  color: #333;
  margin-top: 15px;
  font-size: 14px;
}
.el-cascader {
  max-height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
<style>
.address-dialog {
  width: 50%;
  height: 80%;
}
.address-dialog .el-dialog__body {
  height: 75%;
}
.el-table .cell {
  overflow: inherit !important;
}
</style>
