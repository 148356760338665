var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "outer" }, [
    _c("div", { staticClass: "cont-item" }, [
      _c("h4", [_vm._v("模板基础信息")]),
      _c(
        "div",
        { staticClass: "option", staticStyle: { "padding-left": "0" } },
        [
          _c("i", { staticClass: "must-icon" }, [_vm._v("*")]),
          _c("span", [_vm._v("模板名称：")]),
          _c("el-input", {
            staticClass: "w240",
            attrs: {
              placeholder: "请输入名称",
              size: "mini",
              maxlength: 30,
              disabled: !_vm.isEdit,
            },
            model: {
              value: _vm.moduleName,
              callback: function ($$v) {
                _vm.moduleName = $$v
              },
              expression: "moduleName",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "cont-item" }, [
      _c("h4", [_vm._v("发货区域设置【发货区域和付费区域至少设置一项】")]),
      _c(
        "div",
        { staticClass: "option" },
        [
          _c("span", [_vm._v("包邮区域：")]),
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.areaType,
                callback: function ($$v) {
                  _vm.areaType = $$v
                },
                expression: "areaType",
              },
            },
            [
              _c("el-radio", { attrs: { label: 1 } }, [_vm._v(" 全国 ")]),
              _c("el-radio", { attrs: { label: 2 } }, [_vm._v(" 部分地区 ")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.areaType !== 1
        ? _c(
            "div",
            { staticClass: "option", staticStyle: { "margin-top": "10px" } },
            [
              _c("span", { staticStyle: { "margin-right": "70px" } }),
              _c("el-cascader", {
                attrs: {
                  options: _vm.areaOptions,
                  props: _vm.areaProps,
                  clearable: "",
                  disabled: !_vm.isEdit,
                },
                on: {
                  "visible-change": (bol) =>
                    _vm.visibleChange(bol, _vm.freeValue.area_split.all),
                },
                model: {
                  value: _vm.freeValue.area_split.all,
                  callback: function ($$v) {
                    _vm.$set(_vm.freeValue.area_split, "all", $$v)
                  },
                  expression: "freeValue.area_split.all",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isShowCont && _vm.areaType !== 1
        ? _c("p", { staticClass: "remark" }, [
            _vm._v(" 请点击输入框内容区域可编辑地址 "),
          ])
        : _vm._e(),
    ]),
    _vm.areaType !== 1
      ? _c(
          "div",
          { staticClass: "cont-item" },
          [
            _c("h4", [
              _vm._v("买家付邮费区域【发货区域和付费区域至少设置一项】"),
            ]),
            _c(
              "div",
              { staticClass: "option" },
              [
                _c("span", [_vm._v("计费方式：")]),
                _c(
                  "el-radio",
                  {
                    attrs: { label: "1", disabled: !_vm.isEdit },
                    model: {
                      value: _vm.radio,
                      callback: function ($$v) {
                        _vm.radio = $$v
                      },
                      expression: "radio",
                    },
                  },
                  [_vm._v(" 按件数计费 ")]
                ),
              ],
              1
            ),
            _vm._m(0),
            _c(
              "div",
              { staticClass: "table-box" },
              [
                _c(
                  "el-table",
                  {
                    attrs: {
                      data: _vm.tableData,
                      "header-row-style": {
                        "font-weight": 400,
                        color: "#222",
                        "font-size": "14px",
                      },
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "", label: "运送到", width: "200" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "address-box" },
                                  [
                                    _c("el-cascader", {
                                      attrs: {
                                        options: _vm.areaOptions,
                                        props: _vm.areaProps,
                                        clearable: "",
                                        disabled:
                                          scope.row.disabled &&
                                          scope.row.id !== 0,
                                      },
                                      on: {
                                        "visible-change": (bol) =>
                                          _vm.visibleChange(
                                            bol,
                                            scope.row.area_split.all
                                          ),
                                      },
                                      model: {
                                        value: scope.row.area_split.all,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            scope.row.area_split,
                                            "all",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.area_split.all",
                                      },
                                    }),
                                    scope.row.id && _vm.isEdit
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "mini",
                                              type: "text",
                                            },
                                            on: {
                                              click: function ($event) {
                                                scope.row.disabled =
                                                  !scope.row.disabled
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  scope.row.disabled
                                                    ? "编辑"
                                                    : "完成"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1966610342
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "first_num", label: "首件数(件)" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  staticClass: "w100",
                                  attrs: {
                                    disabled: !_vm.isEdit,
                                    size: "mini",
                                    max: "999",
                                    min: "1",
                                    type: "number",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.fixInteger(
                                        scope.row.first_num,
                                        scope.$index,
                                        "first_num"
                                      )
                                    },
                                  },
                                  model: {
                                    value: scope.row.first_num,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "first_num", $$v)
                                    },
                                    expression: "scope.row.first_num",
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2806911422
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "first_fee", label: "首费(元)" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  staticClass: "w100",
                                  attrs: {
                                    disabled: !_vm.isEdit,
                                    size: "mini",
                                    max: "999",
                                    min: "1",
                                    type: "number",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.firstCost(
                                        scope.row.first_fee,
                                        scope.$index,
                                        "first_fee",
                                        scope.row.each_add_fee
                                      )
                                    },
                                  },
                                  model: {
                                    value: scope.row.first_fee,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "first_fee", $$v)
                                    },
                                    expression: "scope.row.first_fee",
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2045751498
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "each_add", label: "续件数(件)" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  staticClass: "w100",
                                  attrs: {
                                    disabled: !_vm.isEdit,
                                    size: "mini",
                                    max: "999",
                                    min: "1",
                                    type: "number",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.fixInteger(
                                        scope.row.each_add,
                                        scope.$index,
                                        "each_add"
                                      )
                                    },
                                  },
                                  model: {
                                    value: scope.row.each_add,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "each_add", $$v)
                                    },
                                    expression: "scope.row.each_add",
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        184533212
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "each_add_fee", label: "续费(元)" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  staticClass: "w100",
                                  attrs: {
                                    disabled: !_vm.isEdit,
                                    size: "mini",
                                    max: "999",
                                    min: "1",
                                    type: "number",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.fixNextCost(
                                        scope.row.each_add_fee,
                                        scope.row.first_fee,
                                        scope.$index
                                      )
                                    },
                                  },
                                  model: {
                                    value: scope.row.each_add_fee,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "each_add_fee", $$v)
                                    },
                                    expression: "scope.row.each_add_fee",
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1504597412
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "", label: "包邮条件", width: "220" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "condition" },
                                  [
                                    _vm._v(" 满 "),
                                    _c(
                                      "el-input",
                                      {
                                        staticClass: "w160",
                                        attrs: {
                                          disabled: !_vm.isEdit,
                                          type: "number",
                                          size: "mini",
                                          max: "999",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.fixValue(
                                              scope.row.free_value,
                                              scope.row.free_type,
                                              scope.$index,
                                              "free_value",
                                              1
                                            )
                                          },
                                        },
                                        model: {
                                          value: scope.row.free_value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "free_value",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.free_value",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticClass: "w65",
                                            attrs: {
                                              slot: "append",
                                              disabled: !_vm.isEdit,
                                              placeholder: "",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.fixValue(
                                                  scope.row.free_value,
                                                  scope.row.free_type,
                                                  scope.$index,
                                                  "free_value",
                                                  2
                                                )
                                              },
                                            },
                                            slot: "append",
                                            model: {
                                              value: scope.row.free_type,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "free_type",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.free_type",
                                            },
                                          },
                                          [
                                            _c("el-option", {
                                              attrs: { label: " ", value: "0" },
                                            }),
                                            _c("el-option", {
                                              attrs: {
                                                label: "件",
                                                value: "1",
                                              },
                                            }),
                                            _c("el-option", {
                                              attrs: {
                                                label: "元",
                                                value: "2",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" 包邮 "),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1106022308
                      ),
                    }),
                    _vm.isEdit
                      ? _c("el-table-column", {
                          attrs: { prop: "", label: "操作" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteModel(
                                              scope.row.id,
                                              scope.$index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" 删除 ")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            4127313778
                          ),
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.isEdit
              ? _c(
                  "el-button",
                  {
                    attrs: { size: "mini", type: "primary", plain: "" },
                    on: { click: _vm.addCostModel },
                  },
                  [_vm._v(" + 添加一条 ")]
                )
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm.isEdit
      ? _c(
          "div",
          { staticClass: "submit-area" },
          [
            _c(
              "el-button",
              {
                attrs: { size: "mini", type: "primary" },
                on: { click: _vm.submit },
              },
              [_vm._v(" 提交 ")]
            ),
            _c(
              "el-button",
              {
                attrs: { size: "mini", type: "primary", plain: "" },
                on: { click: _vm.goPer },
              },
              [_vm._v(" 取消 ")]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "main" }, [
      _vm._v(
        " 生效范围：合并支付时，店铺内绑定该模板的多个商品（如有）会一起计算运费，"
      ),
      _c("br"),
      _vm._v(
        " 运费按件数平分到各子单；非合并支付时，针对付款的单个商品计算运费。 "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }